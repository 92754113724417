<template>
    <div>
        <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handleUnits"
               v-on:request="trigger=false;" v-on:error="setErrors($event.response)"></axios>

        <div class="container-fluid pl-5 pr-5 content-container">
            <div class="row">
                <div class="col-sm-12 bg-light shadow  p-3 text-left">
                    <p class="heading">Units of measure</p>
                    <hr/>
                    <im type="units"></im>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <success-alert :show="success" :message="msg"></success-alert>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Code</th>
                                <th>Active</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(u,i) in units" v-bind:key="i">
                                <td>{{i + 1}}</td>

                                <td>
                                    <input type="text" @change="update(i)" class="form-control" v-model="u.name">
                                    <span class="text-danger" v-if="index==i">{{getError('name')}}</span>
                                </td>

                                <td>
                                    <input type="text" @change="update(i)" class="form-control" v-model="u.code"
                                           ref="code">
                                    <span class="text-danger" v-if="index==1">{{getError('code')}}</span>
                                </td>
                                <td>
                                    <input type="checkbox" @change="update(i)" v-model="u.is_active"></td>
                                <td>

                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>
<script>

    import form from '@/forms';
    import {Paths} from '@/paths';
    import axios from '@/views/actions/Axios';
    import im from '@/views/layouts/InventoryMenu';
    export default{
        extends: form,
        components: {axios, im},
        data(){
            return {
                url: '',

                units: {},
                index: -1,
                trigger: false,
                request: 'get',
                data: {},
            }
        },
        watch: {
            units(newUnits){
                this.$store.commit('setUnits', newUnits.filter(function (i) {
                    return i.id > 0;
                }));
            },
        },
        methods: {
            empty_model: function () {

                return {id: 0, name: '', code: '', is_active: true};
            },

            handleUnits(data){
                if (this.url == Paths.unit.index) {
                    this.setUnits(data);

                    return;
                }
                this.unitUpdated(data.data);
            },
            update(i){
                this.index = i;
                this.request = 'post';
                this.url = Paths.unit.add;
                this.data = this.units[i];
                this.trigger = true;
            },
            setUnits(data)
            {
                this.units = data;
                this.units.push(this.empty_model());
            },

            unitUpdated(data){
                this.resetRequest();

                if (this.units[this.index].id == 0) {
                    this.units.push(this.empty_model());
                }
                this.units[this.index].id = data.id;
                this.index = -1;
//                this.showSuccessMessage('Unit updated successfully');
            },
            resetRequest(){
                this.request = 'get';
                this.url = Paths.unit.index;
            }
        },
        mounted(){
            this.url = Paths.unit.index;
            this.add_url = Paths.unit.add;
            this.update_url = Paths.unit.update;

            this.trigger = true;

        }

    }
</script>